import React, { useState, useEffect, useMemo } from "react"
import {
  Container,
  Row,
  Col,
  Card,
  Table,
  Form,
  Button,
  Spinner,
  Alert,
  Modal,
} from "react-bootstrap"
import {
  FaSort,
  FaSortUp,
  FaSortDown,
  FaFilter,
  FaSearch,
} from "react-icons/fa"
import { Link } from "react-router-dom"

import DatePicker from "react-datepicker"
import { es } from "date-fns/locale"
import "react-datepicker/dist/react-datepicker.css"

import "./LawyerAssignmentTable.css"
import debounce from "lodash.debounce"
import statsService from "../../services/statsService"

const LawyerAssignmentTable = () => {
  // -----------------------------------------------------
  // RANGO DE FECHAS POR DEFECTO (últimos 7 días)
  // -----------------------------------------------------
  const get7DaysAgo = () => {
    const date = new Date()
    date.setDate(date.getDate() - 7)
    return date
  }
  const [startDate, setStartDate] = useState(get7DaysAgo)
  const [endDate, setEndDate] = useState(new Date())

  // -----------------------------------------------------
  // ESTADOS GENERALES
  // -----------------------------------------------------
  const [data, setData] = useState([])
  const [states, setStates] = useState([])
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(null)

  const [pageNumber, setPageNumber] = useState(1)
  const [pageSize, setPageSize] = useState(50)
  const [totalPages, setTotalPages] = useState(1)
  const [totalRecords, setTotalRecords] = useState(0)

  const [searchQuery, setSearchQuery] = useState("")
  const [visibleStates, setVisibleStates] = useState([])

  const [sortConfig, setSortConfig] = useState({
    key: "total_assignments",
    sort_state_id: null,
    direction: "desc",
  })

  // -----------------------------------------------------
  // ESTADO PARA EL MODAL (mostrar/ocultar)
  // -----------------------------------------------------
  const [showDateModal, setShowDateModal] = useState(false)
  const handleOpenModal = () => setShowDateModal(true)
  const handleCloseModal = () => setShowDateModal(false)

  // -----------------------------------------------------
  // DEBOUNCED SEARCH
  // -----------------------------------------------------
  const debouncedSearch = useMemo(
    () =>
      debounce((query) => {
        setSearchQuery(query)
        setPageNumber(1)
      }, 500),
    []
  )

  const handleSearchChange = (event) => {
    debouncedSearch(event.target.value)
  }

  useEffect(() => {
    return () => {
      debouncedSearch.cancel()
    }
  }, [debouncedSearch])

  // -----------------------------------------------------
  // VISIBILIDAD DE LAS COLUMNAS
  // -----------------------------------------------------
  useEffect(() => {
    setVisibleStates(states)
  }, [states])

  // -----------------------------------------------------
  // FETCH DE DATOS
  // -----------------------------------------------------
  useEffect(() => {
    if (!startDate || !endDate) return

    const fetchData = async () => {
      setLoading(true)
      setError(null)

      const formatDate = (date) => date.toISOString().split("T")[0]
      const formattedStartDate = formatDate(startDate)
      const formattedEndDate = formatDate(endDate)

      try {
        const response = await statsService.getLawyerAssignmentStates(
          formattedStartDate,
          formattedEndDate,
          pageNumber,
          pageSize,
          searchQuery,
          sortConfig.key,
          sortConfig.direction,
          sortConfig.sort_state_id
        )

        const lawyerData = response.data
        const paginationData = response.pagination
        const allStates = response.states.map((state) => state.state)

        setData(lawyerData)
        setStates(allStates)
        setTotalPages(paginationData.total_pages)
        setTotalRecords(paginationData.total_records)
      } catch (err) {
        if (err.response) {
          setError(
            `Error ${err.response.status}: ${
              err.response.data.error || "Algo salió mal."
            }`
          )
        } else if (err.request) {
          setError("No se recibió respuesta del servidor. Revisa tu conexión.")
        } else {
          setError("Ha ocurrido un error inesperado.")
        }
        console.error(err)
      } finally {
        setLoading(false)
      }
    }

    fetchData()
  }, [
    startDate,
    endDate,
    pageNumber,
    pageSize,
    searchQuery,
    sortConfig,
  ])

  // -----------------------------------------------------
  // MANEJO DE RANGO DE FECHAS EN EL DATEPICKER (Modal)
  // -----------------------------------------------------
  const handleDateChange = (dates) => {
    const [start, end] = dates
    setStartDate(start)
    setEndDate(end)
    setPageNumber(1)
  }

  // -----------------------------------------------------
  // MANEJO DE COLUMNAS VISIBLES
  // -----------------------------------------------------
  const handleColumnToggle = (stateName) => {
    if (visibleStates.includes(stateName)) {
      setVisibleStates(visibleStates.filter((s) => s !== stateName))
    } else {
      setVisibleStates([...visibleStates, stateName])
    }
    setPageNumber(1)
  }

  // -----------------------------------------------------
  // MANEJO DE ORDEN (SORT)
  // -----------------------------------------------------
  const handleSort = (key, sort_state_id = null) => {
    const direction =
      sortConfig.key === key && sortConfig.direction === "asc" ? "desc" : "asc"
    if (sort_state_id) {
      setSortConfig({ key, sort_state_id, direction })
    } else {
      setSortConfig({ key, direction, sort_state_id: null })
    }
  }

  const renderSortIcon = (key, sort_state_id = null) => {
    if (sortConfig.key === key) {
      if (sort_state_id && sortConfig.sort_state_id === sort_state_id) {
        return sortConfig.direction === "asc" ? <FaSortUp /> : <FaSortDown />
      } else if (sort_state_id && sortConfig.sort_state_id !== sort_state_id) {
        return <FaSort />
      }
      return sortConfig.direction === "asc" ? <FaSortUp /> : <FaSortDown />
    }
    return <FaSort />
  }

  // -----------------------------------------------------
  // MANEJO DE PAGINACIÓN
  // -----------------------------------------------------
  const handlePageChange = (newPage) => {
    setPageNumber(newPage)
  }

  // -----------------------------------------------------
  // RENDER
  // -----------------------------------------------------
  return (
    <Container fluid className="mt-4">
      <Row>
        <Col xs={12}>
          <Card>
            <Card.Body>
              {/* Texto de arriba de la tabla */}
              <h5 className="mb-3 text-lg ml-2" >
                Listado de abogados activos 
               
              </h5>

              {/* FILA con Lupa/Busqueda + Botones en la misma altura */}
              <div className="d-flex justify-content-between align-items-center mb-3">
                {/* LUPA + INPUT */}
                <div className="d-flex align-items-center">
                  <FaSearch className="me-2" />
                  <Form.Control
                    type="text"
                    placeholder="Buscar por abogado..."
                    onChange={handleSearchChange}
                    style={{ minWidth: "250px" }}
                  />
                </div>

                {/* Botones en la misma línea */}
                <div className="d-flex" style={{ gap: "10px" }}>
                  {/* Botón para abrir modal de fechas */}
                  <Button
                    style={{
                      backgroundColor: "#5f38f9",
                      borderColor: "#5f38f9",
                      color: "#fff",
                      textTransform: "none",
                    }}
                    onClick={handleOpenModal}
                  >
                    Seleccionar fechas
                  </Button>

                  {/* Botón "Columnas" con dropdown */}
                  <div className="btn-group">
                    <Button
                      style={{
                        backgroundColor: "#5f38f9",
                        borderColor: "#5f38f9",
                        color: "#fff",
                        textTransform: "none",
                      }}
                      className="dropdown-toggle"
                      data-bs-toggle="dropdown"
                    >
                      <FaFilter className="me-2" />
                      Columnas
                    </Button>
                    <div className="dropdown-menu p-2">
                      {states.map((stateName) => (
                        <div
                          key={stateName}
                          className="dropdown-item d-flex align-items-center"
                          style={{ cursor: "pointer" }}
                          onClick={() => handleColumnToggle(stateName)}
                        >
                          <Form.Check
                            type="checkbox"
                            label={stateName}
                            checked={visibleStates.includes(stateName)}
                            readOnly
                          />
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>

              {/* Tabla */}
              {loading ? (
                <div className="d-flex justify-content-center align-items-center">
                  <div style={{ minHeight: "511px" }}>
                    <Spinner animation="border" role="status">
                      <span className="visually-hidden">Loading...</span>
                    </Spinner>
                  </div>
                </div>
              ) : error ? (
                <Alert variant="danger">{error}</Alert>
              ) : (
                <div className="mt-4">
                  <div className="table-responsive LawyerAssignmentTable-scroll mb-3">
                    <Table striped bordered hover>
                      <thead>
                        <tr>
                          <th
                            style={{ cursor: "pointer" }}
                            onClick={() => handleSort("lawyer_business_name")}
                          >
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "center",
                                justifyContent: "center",
                              }}
                            >
                              <span>Abogados activos</span>
                              <span>{renderSortIcon("lawyer_business_name")}</span>
                            </div>
                          </th>
                          <th
                            style={{ cursor: "pointer" }}
                            onClick={() => handleSort("total_assignments")}
                          >
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "center",
                                justifyContent: "center",
                              }}
                            >
                              <span>Leads Derivados</span>
                              <span>{renderSortIcon("total_assignments")}</span>
                            </div>
                          </th>
                          {data[0]?.states.map((state) => {
                            if (!visibleStates.includes(state.state_name)) return null
                            return (
                              <th
                                key={state.state_id}
                                style={{ cursor: "pointer" }}
                                onClick={() => handleSort("state", state.state_id)}
                              >
                                <div
                                  style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    alignItems: "center",
                                    justifyContent: "center",
                                  }}
                                >
                                  <span>{state.state_name}</span>
                                  <span>
                                    {renderSortIcon("state", state.state_id)}
                                  </span>
                                </div>
                              </th>
                            )
                          })}
                        </tr>
                      </thead>
                      <tbody>
                        {data.length === 0 ? (
                          <tr>
                            <td
                              colSpan={2 + visibleStates.length}
                              className="text-center"
                            >
                              No hay datos disponibles.
                            </td>
                          </tr>
                        ) : (
                          data.map((lawyer) => (
                            <tr
                              key={lawyer.lawyer_id || lawyer.lawyer_business_name}
                            >
                              <td>
                                <Link to={`/lawyer/${lawyer?.lawyer_id}`}>
                                  {lawyer.lawyer_business_name}
                                </Link>
                              </td>
                              <td>{lawyer.total_assignments}</td>
                              {visibleStates.map((stateName) => {
                                const state = lawyer.states.find(
                                  (s) => s.state_name === stateName
                                )
                                const count = state ? state.lead_count : 0
                                return <td key={stateName}>{count || "-"}</td>
                              })}
                            </tr>
                          ))
                        )}
                      </tbody>
                    </Table>
                  </div>

                  {/* Pagination Controls */}
                  <div className="d-flex justify-content-between align-items-center">
                    <div>
                      Página {pageNumber} de {totalPages} | Resultados totales:{" "}
                      {totalRecords}
                    </div>
                    <div>
                      <Button
                        variant="secondary"
                        size="sm"
                        className="me-2 mt-1"
                        onClick={() => handlePageChange(1)}
                        disabled={pageNumber === 1}
                      >
                        Primera página
                      </Button>
                      <Button
                        variant="secondary"
                        size="sm"
                        className="me-2 mt-1"
                        onClick={() => handlePageChange(pageNumber - 1)}
                        disabled={pageNumber === 1}
                      >
                        Anterior
                      </Button>
                      <Button
                        variant="secondary"
                        size="sm"
                        className="me-2 mt-1"
                        onClick={() => handlePageChange(pageNumber + 1)}
                        disabled={pageNumber === totalPages}
                      >
                        Siguiente
                      </Button>
                      <Button
                        variant="secondary"
                        size="sm"
                        onClick={() => handlePageChange(totalPages)}
                        disabled={pageNumber === totalPages}
                        className="me-2 mt-1"
                      >
                        Última página
                      </Button>
                    </div>
                  </div>
                </div>
              )}
            </Card.Body>
          </Card>
        </Col>
      </Row>

      {/* Modal para el DatePicker (POP-UP) */}
      <Modal show={showDateModal} onHide={handleCloseModal} centered>
        <Modal.Header closeButton>
          <Modal.Title>Seleccionar rango de fechas</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <DatePicker
            selected={startDate}
            onChange={handleDateChange}
            startDate={startDate}
            endDate={endDate}
            selectsRange
            isClearable
            locale={es}
            dateFormat="dd/MM/yyyy"
            className="form-control"
            placeholderText="Selecciona un rango de fechas"
            showMonthDropdown
            showYearDropdown
            dropdownMode="select"
            showPopperArrow
            calendarStartDay={1} // semana empieza lunes
          />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModal}>
            Cerrar
          </Button>
        </Modal.Footer>
      </Modal>
    </Container>
  )
}

export default LawyerAssignmentTable
