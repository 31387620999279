import React, { useEffect, useState, forwardRef } from "react";
import ReactDOM from "react-dom";
import { Card, Button, Dropdown, Badge } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { Icon, CustomDropdownToggle, LinkList } from "../../../components";
import Layout from "../../../layout/default";
import Block from "../../../components/Block/Block";
import DataTable from "../../../components/DataTable/DataTable.js";
import notificationService from "../services/notificationService";

// Componente para renderizar el menú en un portal
const PortalDropdownMenu = forwardRef(
  ({ children, style, className, ...props }, ref) => {
    // Se fuerza posicionamiento absoluto y un z-index alto
    const menu = (
      <div
        ref={ref}
        style={{ ...style, position: "absolute", zIndex: 9999 }}
        className={className}
        {...props}
      >
        {children}
      </div>
    );
    return ReactDOM.createPortal(menu, document.body);
  }
);

const NotificationList = () => {
  const navigate = useNavigate();
  const [notificationData, setNotification] = useState([]);
  const [loading, setLoading] = useState(false);
  const [userData, setUser] = useState([]);

  useEffect(() => {
    setLoading(true);
    fetchNotificationList();
    fetchUserList();
  }, []);

  const fetchUserList = async () => {
    try {
      const user = await notificationService.getUserList();
      setUser(user);
    } catch (error) {
      console.log("Error fetching user list:", error);
    }
  };

  const fetchNotificationList = async () => {
    try {
      const response = await notificationService.getNotificationList();
      setNotification(response);
      setLoading(false);
    } catch (error) {
      console.log("Error fetching notification list:", error);
      setLoading(false);
    }
  };

  const handleDeleteNotification = async (notificationId) => {
    try {
      setLoading(true);
      await notificationService.deleteNotification(notificationId);
      const data = notificationData.filter((row) => row.id !== notificationId);
      setNotification(data);
      setLoading(false);
    } catch (error) {
      console.log("Error deleting notification:", error);
      setLoading(false);
    }
  };

  const handleCreateNotification = () => {
    navigate("/notification/new");
  };

  const notificationColumns = [
    {
      name: "id",
      selector: (row) => row.id,
      sortable: true,
    },
    {
      name: "usuario",
      selector: (row) => {
        const user = userData.find((user) => user.id === row.user);
        return user ? user.name : "";
      },
      sortable: true,
      cell: (row) => {
        const user = userData.find((user) => user.id === row.user);
        return user ? user.name : "";
      },
    },
    {
      name: "mensaje",
      selector: (row) => row.message,
      sortable: true,
    },
    {
      name: "leido",
      selector: (row) => {
        if (row.read) {
          return <Badge bg="success">Si</Badge>;
        } else {
          return <Badge bg="warning">No</Badge>;
        }
      },
      sortable: true,
    },
    {
      name: "acciones",
      cell: (row) => (
        <div className="text-end w-100">
          <Dropdown>
            <Dropdown.Toggle
              size="sm"
              as={CustomDropdownToggle}
              className="btn btn-sm btn-icon btn-zoom me-n1"
            >
              <Icon name="more-v" />
            </Dropdown.Toggle>
            <Dropdown.Menu
              as={PortalDropdownMenu} // Usamos el menú que renderiza en el body
              align="end"
            >
              <div className="dropdown-content py-1">
                <LinkList className="link-list-hover-bg-primary link-list-md">
                  <Link
                    to={`/notification/edit/${row.id}`}
                    className="dropdown-item"
                  >
                    <Icon name="edit" />
                    <span>Editar</span>
                  </Link>
                  <button
                    className="dropdown-item"
                    onClick={() => handleDeleteNotification(row.id)}
                  >
                    <Icon name="trash" />
                    <span>Borrar</span>
                  </button>
                </LinkList>
              </div>
            </Dropdown.Menu>
          </Dropdown>
        </div>
      ),
      sortable: false,
    },
  ];

  return (
    <Layout title="Notification List" content="container">
      <Block.Head>
        <Block.HeadBetween>
          <Block.HeadContent>
            <Block.Title tag="h2">Lista de notificaciones</Block.Title>
            <nav>
              <ol className="breadcrumb breadcrumb-arrow mb-0">
                <li className="breadcrumb-item">
                  <Link to="/">Home</Link>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                  Notificación
                </li>
              </ol>
            </nav>
          </Block.HeadContent>
          <Block.HeadContent>
            <ul className="d-flex">
              <li>
                <Button
                  className="d-md-none"
                  size="md"
                  variant="primary"
                  onClick={handleCreateNotification}
                >
                  <Icon name="plus" />
                  <span>Añadir</span>
                </Button>
              </li>
              <li>
                <Button
                  className="d-none d-md-inline-flex"
                  variant="primary"
                  onClick={handleCreateNotification}
                >
                  <Icon name="plus" />
                  <span>Añadir notificación</span>
                </Button>
              </li>
            </ul>
          </Block.HeadContent>
        </Block.HeadBetween>
      </Block.Head>

      <Block>
        {/* Se fuerza overflow visible para evitar recortes */}
        <Card style={{ overflow: "visible" }}>
          {!loading ? (
            <DataTable
              tableClassName="data-table-head-light table-responsive"
              data={notificationData}
              columns={notificationColumns}
              expandableRows
              actions
            />
          ) : (
            <p>Loading...</p>
          )}
        </Card>
      </Block>
    </Layout>
  );
};

export default NotificationList;
