import React from "react"

function Footer() {
  return (
    <div className="nk-footer">
      <div className="container-fluid">
        <div className="nk-footer-wrap">
          <div className="nk-footer-copyright">
            <span style={{ fontSize: "larger" }}>
              Develop by{" "}
              <a href="https://www.fiva.es/software" target="_blank">
                Fiva E&S.
              </a>
            </span>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Footer
