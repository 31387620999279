import React, { useEffect, useState } from "react"
import {
  PieChart,
  Pie,
  Cell,
  Tooltip,
  ResponsiveContainer,
} from "recharts"
import {
  Spinner,
  Alert,
  Form,
  Button,
  Modal,
} from "react-bootstrap"
import { Card, Typography } from "@mui/material"
import { FaFilter } from "react-icons/fa"
import DatePicker from "react-datepicker"
import { es } from "date-fns/locale"
import "react-datepicker/dist/react-datepicker.css"

import statsService from "../../services/statsService" // Ajusta la ruta si fuese necesario

const LeadStatePieChart = () => {
  // -----------------------------------------------------
  // 1. Fechas: Últimos 7 días por defecto + Modal p/rango
  // -----------------------------------------------------
  const get7DaysAgo = () => {
    const date = new Date()
    date.setDate(date.getDate() - 7)
    return date
  }

  const [startDate, setStartDate] = useState(get7DaysAgo)
  const [endDate, setEndDate] = useState(new Date())
  const [showDateModal, setShowDateModal] = useState(false)

  const openDateModal = () => setShowDateModal(true)
  const closeDateModal = () => setShowDateModal(false)

  const handleDateChange = (dates) => {
    const [start, end] = dates
    setStartDate(start)
    setEndDate(end)
  }

  // -----------------------------------------------------
  // 2. ESTADOS VARIOS
  // -----------------------------------------------------
  const [loading, setLoading] = useState(false)
  const [chartData, setChartData] = useState([])
  const [error, setError] = useState(null)
  const [totalLeadCount, setTotalLeadCount] = useState(0)

  // Especialidades y selección
  const [specialties, setSpecialties] = useState([])
  const [selectedSpecialties, setSelectedSpecialties] = useState([])
  const [isInitialized, setIsInitialized] = useState(false)
  const [tempSelectedSpecialties, setTempSelectedSpecialties] = useState([])

  // Colores del gráfico
  const COLORS = [
    "#8884d8",
    "#82ca9d",
    "#ff7300",
    "#0088fe",
    "#ffbb28",
    "#ff8042",
    "#a4de6c",
    "#8dd1e1",
    "#d88884",
    "#FFC0CB",
    "#FF69B4",
  ]

  // -----------------------------------------------------
  // 3. FETCH DE DATOS
  // -----------------------------------------------------
  const formatDate = (date) => date.toISOString().split("T")[0]

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true)
      setError(null)
      try {
        const formattedStartDate = startDate ? formatDate(startDate) : null
        const formattedEndDate = endDate ? formatDate(endDate) : null

        const response = await statsService.getDerivedLeadStates(
          formattedStartDate,
          formattedEndDate,
          selectedSpecialties
        )
        const { data } = response

        if (!data || Object.keys(data).length === 0) {
          console.warn("No data returned from API.")
          setChartData([])
          setTotalLeadCount(0)
          return
        }

        const processedData = Object.values(data.states).map((stateObj) => {
          return {
            name: stateObj.state || "Estado desconocido",
            value: stateObj.percentage,
            count: stateObj.lead_count || 0,
          }
        })

        setTotalLeadCount(data.total_lead_count)
        setChartData(processedData)

        // Inicialización de especialidades (solo 1 vez)
        if (!isInitialized) {
          const allSpecialties = data.specialties || []
          setSpecialties(allSpecialties)
          const allSpecialtyIds = allSpecialties.map((s) => s.id)
          setSelectedSpecialties(allSpecialtyIds)
          setTempSelectedSpecialties(allSpecialtyIds)
          setIsInitialized(true)
        }
      } catch (error) {
        console.error("Error fetching data:", error)
        setError("Hubo un error al cargar los datos. Por favor, inténtalo de nuevo.")
      } finally {
        setLoading(false)
      }
    }

    fetchData()
  }, [startDate, endDate, selectedSpecialties, isInitialized])

  // -----------------------------------------------------
  // 4. TOGGLE DE ESPECIALIDADES
  // -----------------------------------------------------
  const handleSpecialtyToggle = (id) => {
    if (tempSelectedSpecialties.includes(id)) {
      setTempSelectedSpecialties(
        tempSelectedSpecialties.filter((specialtyId) => specialtyId !== id)
      )
    } else {
      setTempSelectedSpecialties([...tempSelectedSpecialties, id])
    }
  }

  const applySpecialtyFilters = () => {
    setSelectedSpecialties(tempSelectedSpecialties)
  }

  // -----------------------------------------------------
  // 5. Tooltip personalizado
  // -----------------------------------------------------
  const CustomTooltip = ({ active, payload }) => {
    if (active && payload && payload.length) {
      const { name, value, count } = payload[0].payload
      return (
        <Card
          style={{
            padding: "15px 20px",
            borderRadius: "10px",
            backgroundColor: "rgba(255, 255, 255, 0.75)",
            boxShadow: "0 4px 20px rgba(0, 0, 0, 0.1)",
            pointerEvents: "none",
          }}
        >
          <Typography
            variant="h6"
            style={{ fontWeight: "bold", color: "#000", marginBottom: "5px" }}
          >
            {name} {value}%
          </Typography>
          <Typography>
            {count} de {totalLeadCount} leads
          </Typography>
        </Card>
      )
    }
    return null
  }

  // -----------------------------------------------------
  // RENDER
  // -----------------------------------------------------
  return (
    <div style={{ padding: "20px 0" }}>
      {/* Título + Botones en la misma línea */}
      <div className="d-flex justify-content-between align-items-center mb-3">
        <h5 className="mb-0 text-lg ml-2">Estado de leads derivados</h5>

        {/* Botones a la derecha, en la misma línea */}
        <div className="d-flex" style={{ gap: "10px" }}>
          {/* Botón para el modal de fechas */}
          <Button
            variant="contained"
            style={{
              backgroundColor: "#5f38f9",
              borderColor: "#5f38f9",
              color: "#fff",
              textTransform: "none",
            }}
            onClick={openDateModal}
            
          >
            Seleccionar fechas
          </Button>

          {/* Botón de especialidades */}
          <div className="dropdown" >
            <Button
              variant="contained"
              startIcon={<FaFilter />}
              className="dropdown-toggle"
              data-bs-toggle="dropdown"
              style={{
                backgroundColor: "#5f38f9",
                borderColor: "#5f38f9",
                color: "#fff",
                textTransform: "none",
              }}
            >
              Especialidades
            </Button>
            <div className="dropdown-menu p-2">
              {specialties.length === 0 ? (
                <div className="px-2 py-1">No hay especialidades disponibles.</div>
              ) : (
                <>
                  {specialties.map((specialty) => (
                    <Form.Check
                      key={specialty.id}
                      type="checkbox"
                      id={`specialty-${specialty.id}`}
                      label={specialty.name}
                      checked={tempSelectedSpecialties.includes(specialty.id)}
                      onChange={() => handleSpecialtyToggle(specialty.id)}
                    />
                  ))}
                  <div className="d-flex justify-content-center p-2">
                    <Button
                      variant="secondary"
                      size="sm"
                      
                      onClick={applySpecialtyFilters}
                      disabled={loading}
                      className="me-2"
                    >
                      Aplicar filtros
                    </Button>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>

      {/* Contenido principal: Loading / Error / PieChart */}
      {loading ? (
        <div
          className="d-flex justify-content-center align-items-center"
          style={{ height: "400px" }}
        >
          <Spinner animation="border" role="status">
            <span className="visually-hidden">Cargando...</span>
          </Spinner>
        </div>
      ) : error ? (
        <Alert variant="danger">{error}</Alert>
      ) : chartData?.length === 0 ? (
        <Alert variant="info">
          No hay datos disponibles para el rango de fechas seleccionado.
        </Alert>
      ) : (
        <ResponsiveContainer width="100%" minHeight={550}>
          <PieChart>
            <Pie
              data={chartData}
              dataKey="value"
              nameKey="name"
              cx="50%"
              cy="50%"
              innerRadius={110}
              outerRadius={220}
              labelLine={false}
              label={({ name, value }) =>
                value > 0.5 ? `${name} (${value}%)` : null
              }
            >
              {chartData.map((entry, index) => (
                <Cell
                  key={`cell-${index}`}
                  fill={COLORS[index % COLORS.length]}
                />
              ))}
            </Pie>
            <Tooltip content={<CustomTooltip />} />
          </PieChart>
        </ResponsiveContainer>
      )}

      {/* MODAL para rango de fechas */}
      <Modal show={showDateModal} onHide={closeDateModal} centered>
        <Modal.Header closeButton>
          <Modal.Title>Seleccionar rango de fechas</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <DatePicker
            selected={startDate}
            onChange={handleDateChange}
            startDate={startDate}
            endDate={endDate}
            selectsRange
            isClearable
            locale={es}
            dateFormat="dd/MM/yyyy"
            className="form-control"
            placeholderText="Selecciona un rango de fechas"
            showMonthDropdown
            showYearDropdown
            dropdownMode="select"
            showPopperArrow
            calendarStartDay={1} // semana empieza lunes
          />
          <Typography variant="body2" style={{ marginTop: 10 }}>
            Si borras las fechas, se mostrará el histórico.
          </Typography>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" 
          onClick={closeDateModal}>
            
            Cerrar
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  )
}

export default LeadStatePieChart
