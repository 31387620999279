import { Card, Button, Dropdown } from "react-bootstrap"
import { Link, useNavigate } from "react-router-dom"
import {
  Icon,
  CustomDropdownToggle,
  CustomDropdownMenu,
  LinkList,
} from "../../../components"
import Layout from "../../../layout/default"
import Block from "../../../components/Block/Block"
import DataTable from "../../../components/DataTable/DataTable.js"

import React, { useEffect, useState } from "react"
import leadSubstateService from "../services/lead_substateService"
import leadStateService from "../../lead_state/services/lead_stateService"

const Lead_substateList = () => {
  const navigate = useNavigate()
  const [lead_substateData, setLead_substate] = useState([])
  const [loading, setLoading] = useState(false)
  const [states, setStates] = useState([])
  useEffect(() => {
    setLoading(true)
    fetchLead_substateList()
    fetchStates()
  }, [])

  const fetchLead_substateList = async () => {
    try {
      const response = await leadSubstateService.getLead_substateList()
      setLead_substate(response)
      setLoading(false)
    } catch (error) {
      console.log("Error fetching lead_substate list:", error)
      setLoading(false)
    }
  }

  const fetchStates = async () => {
    try {
      const statesData = await leadStateService.getLead_stateList()
      setStates(statesData)
    } catch (error) {
      console.log("Error fetching lead states:", error)
    }
  }
  const handleDeleteLead_substate = async (lead_substateId) => {
    try {
      setLoading(true)
      await leadSubstateService.deleteLead_substate(lead_substateId)
      const data = lead_substateData.filter(
        (rows) => rows.id !== lead_substateId
      )
      setLead_substate(data)
      setLoading(false)
    } catch (error) {
      console.log("Error deleting lead_substate:", error)
      setLoading(false)
    }
  }

  const handleCreateLead_substate = () => {
    navigate("/lead_substate/new")
  }

  const lead_substateColumns = [
    {
      name: "id",
      selector: (row) => row.id,
      sortable: true,
    },
    {
      name: "Estado principal",
      selector: (row) => {
        const state = states.find((state) => state.id === row.main_state)
        return state ? state.state : ""
      },
      sortable: true,
    },
    {
      name: "Motivo",
      selector: (row) => row.substate,
      sortable: true,
    },
    {
      name: "acciones",
      cell: (row) => (
        <div className="text-end w-100">
          <Dropdown>
            <Dropdown.Toggle
              size="sm"
              as={CustomDropdownToggle}
              className="btn btn-sm btn-icon btn-zoom me-n1"
            >
              <Icon name="more-v" />
            </Dropdown.Toggle>
            <Dropdown.Menu
              className="dropdown-menu-sm"
              as={CustomDropdownMenu}
              align="end"
            >
              <div className="dropdown-content py-1">
                <LinkList className="link-list-hover-bg-primary link-list-md">
                  <Link
                    to={`/lead_substate/edit/${row.id}`}
                    className="dropdown-item"
                  >
                    <Icon name="edit" />
                    <span>Edit</span>
                  </Link>
                  <button
                    className="dropdown-item"
                    onClick={() => handleDeleteLead_substate(row.id)}
                  >
                    <Icon name="trash" />
                    <span>Delete</span>
                  </button>
                </LinkList>
              </div>
            </Dropdown.Menu>
          </Dropdown>
        </div>
      ),
      sortable: false,
    },
  ]

  return (
    <Layout title="Motivos de estado" content="container">
      <Block.Head>
        <Block.HeadBetween>
          <Block.HeadContent>
            <Block.Title tag="h2">Motivos de estado</Block.Title>
            <nav>
              <ol className="breadcrumb breadcrumb-arrow mb-0">
                <li className="breadcrumb-item">
                  <Link to="/">Home</Link>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                  Motivos de estado
                </li>
              </ol>
            </nav>
          </Block.HeadContent>
          <Block.HeadContent>
            <ul className="d-flex">
              <li>
                <Button
                  className="d-md-none"
                  size="md"
                  variant="primary"
                  onClick={handleCreateLead_substate}
                >
                  <Icon name="plus" />
                  <span>Add</span>
                </Button>
              </li>
              <li>
                <Button
                  className="d-none d-md-inline-flex"
                  variant="primary"
                  onClick={handleCreateLead_substate}
                >
                  <Icon name="plus" />
                  <span>Añadir motivo a estado</span>
                </Button>
              </li>
            </ul>
          </Block.HeadContent>
        </Block.HeadBetween>
      </Block.Head>

      <Block>
        <Card>
          {!loading ? (
            <DataTable
              tableClassName="data-table-head-light table-responsive"
              data={lead_substateData}
              columns={lead_substateColumns}
              expandableRows
              actions
            />
          ) : (
            <p>Loading...</p>
          )}
        </Card>
      </Block>
    </Layout>
  )
}

export default Lead_substateList
