import { useState, useEffect } from "react";
import { Card, Button, Row, Col, Form } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import leadSubstateService from "../services/lead_substateService";
// Import the lead state service to fetch the list of states
import leadStateService from "../../lead_state/services/lead_stateService";
import Layout from "../../../layout/default";
import Block from "../../../components/Block/Block";

function Lead_substateForm() {
  const navigate = useNavigate();
  const { id } = useParams();

  // Initialize the lead substate data with properties for substate and main_state.
  const [lead_substateData, setLead_substateData] = useState({
    substate: "",
    main_state: "",
  });

  // State for storing the list of lead states for the drop-down.
  const [states, setStates] = useState([]);

  useEffect(() => {
    // Fetch the list of available lead states
    fetchStates();
    if (id) {
      // If editing, fetch the existing lead substate data.
      fetchLead_substate();
    }
  }, [id]);

  const fetchStates = async () => {
    try {
      const statesData = await leadStateService.getLead_stateList();
      setStates(statesData);
    } catch (error) {
      console.log("Error fetching lead states:", error);
    }
  };

  const fetchLead_substate = async () => {
    try {
      const lead_substate = await leadSubstateService.getLead_substateDetails(id);
      // In case the backend returns the main_state as an object, extract its id.
      const main_state_id =
        typeof lead_substate.main_state === "object"
          ? lead_substate.main_state.id
          : lead_substate.main_state;
      setLead_substateData({
        ...lead_substate,
        main_state: main_state_id,
      });
    } catch (error) {
      console.log("Error fetching lead substate:", error);
    }
  };

  const handleChange = (event) => {
    console.log("STATE");
    
    console.log(event.target.name, event.target.value);
    console.log(lead_substateData);
    
    
    setLead_substateData({
      ...lead_substateData,
      [event.target.name]: event.target.value,
    });
  };

  const handleCreateOrUpdateLead_substate = async (event) => {
    event.preventDefault();
    try {
      if (id) {
        await leadSubstateService.updateLead_substate(id, lead_substateData);
      } else {
        await leadSubstateService.createLead_substate(lead_substateData);
      }
      navigate("/lead_substate");
    } catch (error) {
      console.log("Error creating/updating lead substate:", error);
    }
  };

  const pageTitle = id ? "Editar Motivo" : "Añadir Motivo a Estado";
  const submitButtonText = id ? "Actualizar" : "Guardar";

  return (
    <Layout title={pageTitle} content="container">
      <Block>
        <Card className="card-gutter-md">
          <Card.Body>
            <div className="bio-block">
              <h4 className="bio-block-title mb-4">{pageTitle}</h4>
              <Form onSubmit={handleCreateOrUpdateLead_substate}>
                <Row className="g-3">
                  {/* Main State Selector */}
                  <Col lg="12">
                    <Form.Group className="form-group">
                      <Form.Label htmlFor="main_state">Estado Principal</Form.Label>
                      <div className="form-control-wrap">
                        <Form.Control
                          as="select"
                          id="main_state"
                          name="main_state"
                          value={lead_substateData.main_state}
                          onChange={handleChange}
                        >
                          <option value="">Selecciona un estado principal</option>
                          {states.map((state) => (
                            <option key={state.id} value={state.id}>
                              {state.state}
                            </option>
                          ))}
                        </Form.Control>
                      </div>
                    </Form.Group>
                  </Col>
                  {/* Substate Input */}
                  <Col lg="12">
                    <Form.Group className="form-group">
                      <Form.Label htmlFor="substate">Motivo</Form.Label>
                      <div className="form-control-wrap">
                        <Form.Control
                          type="text"
                          id="substate"
                          placeholder="Motivo del estado"
                          name="substate"
                          value={lead_substateData.substate}
                          onChange={handleChange}
                        />
                      </div>
                    </Form.Group>
                  </Col>
                  <Col lg="12">
                    <Button variant="primary" type="submit">
                      {submitButtonText}
                    </Button>
                  </Col>
                </Row>
              </Form>
            </div>
          </Card.Body>
        </Card>
      </Block>
    </Layout>
  );
}

export default Lead_substateForm;
