import React, { useState, useEffect, useMemo } from "react"
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  ResponsiveContainer,
  Cell,
} from "recharts"
import { Form, Modal,Button } from "react-bootstrap"
import { CircularProgress, Typography, Card } from "@mui/material"
import { FaFilter } from "react-icons/fa"
import DatePicker from "react-datepicker"
import { es } from "date-fns/locale"
import "react-datepicker/dist/react-datepicker.css"

import statsService from "../../services/statsService"

const SpecialtyAssignmentBarChart = () => {
  // -------------------------------------------------------
  // Fechas: último 7 días por defecto + Modal para rango
  // -------------------------------------------------------
  const get7DaysAgo = () => {
    const date = new Date()
    date.setDate(date.getDate() - 7)
    return date
  }

  const [startDate, setStartDate] = useState(get7DaysAgo)
  const [endDate, setEndDate] = useState(new Date())
  const [showDateModal, setShowDateModal] = useState(false)

  const openDateModal = () => setShowDateModal(true)
  const closeDateModal = () => setShowDateModal(false)

  const handleDateChange = (dates) => {
    const [start, end] = dates
    setStartDate(start)
    setEndDate(end)
  }

  // -------------------------------------------------------
  // Estados para data
  // -------------------------------------------------------
  const [loading, setLoading] = useState(false)
  const [chartData, setChartData] = useState([])

  const [specialties, setSpecialties] = useState([])
  const [selectedSpecialties, setSelectedSpecialties] = useState([])
  const [hoveredBar, setHoveredBar] = useState(null)

  // -------------------------------------------------------
  // Fetch de datos (si startDate o endDate son null => histórico)
  // -------------------------------------------------------
  useEffect(() => {
    const fetchData = async () => {
      setLoading(true)
      try {
        const formatDate = (date) => date.toISOString().split("T")[0]

        const formattedStartDate =
          startDate != null ? formatDate(startDate) : null
        const formattedEndDate = endDate != null ? formatDate(endDate) : null

        const response = await statsService.getSpecialtiesAssignmentStates(
          formattedStartDate,
          formattedEndDate
        )
        const { data } = response

        if (!data || Object.keys(data).length === 0) {
          console.warn("No data returned from API.")
          setChartData([])
          setSpecialties([])
          setSelectedSpecialties([])
          return
        }

        const specialtiesSet = new Set()
        const processedData = []

        Object.values(data).forEach((stateObj) => {
          const stateName = stateObj.name || "Estado desconocido"
          const stateData = { state: stateName }

          if (stateObj.specialties) {
            const specialtiesArray = Array.isArray(stateObj.specialties)
              ? stateObj.specialties
              : Object.values(stateObj.specialties)

            specialtiesArray.forEach((specialty) => {
              const specialtyName = specialty.name || "Desconocida"
              const percentage = parseFloat(specialty.percentage) || 0

              stateData[specialtyName] = percentage
              specialtiesSet.add(specialtyName)
            })
          } else {
            // Caso sin "specialties"
            const specialtyName = stateObj.name || "Desconocida"
            const percentage = parseFloat(stateObj.percentage) || 0

            stateData[specialtyName] = percentage
            specialtiesSet.add(specialtyName)
          }

          processedData.push(stateData)
        })

        const specialtiesList = Array.from(specialtiesSet)

        setChartData(processedData)
        setSpecialties(specialtiesList)
        setSelectedSpecialties(specialtiesList)
      } catch (error) {
        console.error("Error fetching data:", error)
      } finally {
        setLoading(false)
      }
    }

    fetchData()
  }, [startDate, endDate])

  // -------------------------------------------------------
  // Toggle de especialidades
  // -------------------------------------------------------
  const handleSpecialtyToggle = (specialty) => {
    setSelectedSpecialties((prevSelected) =>
      prevSelected.includes(specialty)
        ? prevSelected.filter((s) => s !== specialty)
        : [...prevSelected, specialty]
    )
  }

  // Si la especialidad en hover deja de estar seleccionada, quitamos hover
  useEffect(() => {
    if (hoveredBar && !selectedSpecialties.includes(hoveredBar?.specialty)) {
      setHoveredBar(null)
    }
  }, [selectedSpecialties, hoveredBar])

  // -------------------------------------------------------
  // Colores de las barras
  // -------------------------------------------------------
  const colors = [
    "#8884d8",
    "#82ca9d",
    "#ff7300",
    "#0088fe",
    "#ffbb28",
    "#ff8042",
    "#a4de6c",
    "#8dd1e1",
    "#d88884",
    "#FFC0CB",
    "#FF69B4",
  ]

  // -------------------------------------------------------
  // Tooltip personalizado
  // -------------------------------------------------------
  const CustomTooltip = ({ active, payload }) => {
    if (active && payload && payload.length) {
      return (
        <Card
          style={{
            padding: "15px 20px",
            borderRadius: "10px",
            backgroundColor: "rgba(255, 255, 255, 0.75)",
            boxShadow: "0 4px 20px rgba(0, 0, 0, 0.1)",
            pointerEvents: "none",
          }}
        >
          {hoveredBar ? (
            <>
              <Typography
                variant="h6"
                style={{
                  fontWeight: "bold",
                  color: "#000",
                  marginBottom: "5px",
                }}
              >
                {hoveredBar.specialty}
              </Typography>
              <Typography>
                {
                  chartData.find((item) => item.state === hoveredBar.state)[
                    hoveredBar.specialty
                  ]
                }
                % en {hoveredBar.state}
              </Typography>
            </>
          ) : (
            <div
              style={{
                height: "60px",
                width: "200px",
              }}
            ></div>
          )}
        </Card>
      )
    }
    return null
  }

  // -------------------------------------------------------
  // Render
  // -------------------------------------------------------
  return (
    <div style={{ padding: "20px 0" }}>
      {/* Encabezado + Botones en la misma línea */}
      <div className="d-flex justify-content-between align-items-center mb-3 mt-1">
        <h5 className="mb-0 text-lg ml-2">Convertibilidad por categoría general</h5>

        {/* Botones a la derecha en la misma línea */}
        <div className="d-flex" style={{ gap: "10px" }}>
          {/* Botón para fechas */}
          <Button
            variant="contained"
            onClick={openDateModal}
            style={{
              backgroundColor: "#5f38f9",
              borderColor: "#5f38f9",
              color: "#fff",
              textTransform: "none",
            }}
          >
            Seleccionar fechas
          </Button>

          {/* Botón-Dropdown de especialidades */}
          <div className="dropdown">
            <Button
              variant="contained"
              startIcon={<FaFilter />}
              className="dropdown-toggle"
              data-bs-toggle="dropdown"
              style={{
                backgroundColor: "#5f38f9",
                borderColor: "#5f38f9",
                color: "#fff",
                textTransform: "none",
              }}
            >
              Especialidades
            </Button>
            <div className="dropdown-menu p-2">
              {specialties.map((specialty) => (
                <div
                  key={specialty}
                  className="dropdown-item d-flex align-items-center"
                  style={{ cursor: "pointer" }}
                  onClick={() => handleSpecialtyToggle(specialty)}
                >
                  <Form.Check
                    type="checkbox"
                    label={specialty}
                    checked={selectedSpecialties.includes(specialty)}
                    readOnly
                  />
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>

      {/* Loading / Chart */}
      {loading ? (
        <div style={{ textAlign: "center", padding: "50px" }}>
          <CircularProgress />
        </div>
      ) : chartData?.length > 0 ? (
        <ResponsiveContainer width="100%" height={500}>
          <BarChart
            data={chartData}
            margin={{ top: 20, right: 30, left: 20, bottom: 20 }}
          >
            <XAxis
              dataKey="state"
              label={{
                value: "Estados",
                position: "insideBottom",
                offset: -20,
              }}
            />
            <YAxis
              label={{
                value: "Porcentaje (%)",
                angle: -90,
                position: "insideLeft",
              }}
            />
            <Tooltip content={<CustomTooltip />} />
            <Legend
              verticalAlign="top"
              iconType="circle"
              align="center"
              wrapperStyle={{ paddingBottom: "20px" }}
            />
            {selectedSpecialties.map((specialty, index) => (
              <Bar
                key={specialty}
                dataKey={specialty}
                fill={colors[index % colors.length]}
                barSize={20}
                background={{ fill: "rgba(0, 0, 0, 0.01)" }}
                style={{ cursor: "pointer" }}
              >
                {chartData?.map((entry, idx) => {
                  const isHovered =
                    hoveredBar &&
                    hoveredBar.state === entry.state &&
                    hoveredBar.specialty === specialty

                  return (
                    <Cell
                      key={`cell-${idx}`}
                      fill={
                        isHovered
                          ? "rgba(0, 0, 0, 0.8)"
                          : colors[index % colors.length]
                      }
                      opacity={isHovered ? 1 : 0.8}
                      onMouseEnter={() =>
                        setHoveredBar({ state: entry.state, specialty })
                      }
                      onMouseLeave={() => setHoveredBar(null)}
                      style={{ transition: "all 0.15s ease" }}
                    />
                  )
                })}
              </Bar>
            ))}
          </BarChart>
        </ResponsiveContainer>
      ) : (
        <Typography variant="body1">No hay datos disponibles</Typography>
      )}

      {/* Modal para seleccionar rango de fechas */}
      <Modal show={showDateModal} onHide={closeDateModal} centered>
        <Modal.Header closeButton>
          <Modal.Title>Seleccionar rango de fechas</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <DatePicker
            selected={startDate}
            onChange={handleDateChange}
            startDate={startDate}
            endDate={endDate}
            selectsRange
            isClearable
            locale={es}
            dateFormat="dd/MM/yyyy"
            className="form-control"
            placeholderText="Selecciona un rango de fechas"
            showMonthDropdown
            showYearDropdown
            dropdownMode="select"
            showPopperArrow
            calendarStartDay={1} // semana empieza lunes
          />
          <Typography variant="body2" style={{ marginTop: 10 }}>
            Si borras las fechas, se mostrará el histórico.
          </Typography>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="contained"
            onClick={closeDateModal}
            sx={{
              backgroundColor: "#5f38f9",
              textTransform: "none",
              "&:hover": {
                backgroundColor: "#5f38f9",
              },
            }}
          >
            Cerrar
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  )
}

export default SpecialtyAssignmentBarChart
