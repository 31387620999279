import axios from "axios";

class LeadSubstateService {
  api;
  constructor() {
    this.api = axios.create({
      baseURL: `${process.env.REACT_APP_API_URL}`,
    });
    this.api.interceptors.request.use((config) => {
      const token = localStorage.getItem("Token");

      if (token) {
        config.headers = {
          Authorization: `Token ${token}`,
          withCredentials: false,
          "Content-Type": "application/json; charset=UTF-8",
        };
      }

      return config;
    });
  }

  getLead_substateList = async () => {
    try {
      const response = await this.api.get(`/lead-substate/`);
      return response.data;
    } catch (error) {
      throw error;
    }
  };

  getLead_substateDetails = async (lead_substateId) => {
    try {
      const response = await this.api.get(`/lead-substate/${lead_substateId}/`);
      return response.data;
    } catch (error) {
      throw error;
    }
  };

  createLead_substate = async (lead_substateData) => {
    try {
      const response = await this.api.post(`/lead-substate/`, lead_substateData);
      return response.data;
    } catch (error) {
      throw error;
    }
  };

  updateLead_substate = async (lead_substateId, lead_substateData) => {
    try {
      const response = await this.api.put(
        `/lead-substate/${lead_substateId}/`,
        lead_substateData
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  };

  deleteLead_substate = async (lead_substateId) => {
    try {
      const response = await this.api.delete(`/lead-substate/${lead_substateId}/`);
      return response.data;
    } catch (error) {
      throw error;
    }
  };
}

const leadSubstateService = new LeadSubstateService();

export default leadSubstateService;
