import { useState, useEffect } from "react"
import { Card, Button, Row, Col, Form, Alert } from "react-bootstrap"
import { useNavigate, useParams } from "react-router-dom"
import notificationService from "../services/notificationService"
import Layout from "../../../layout/default"
import Block from "../../../components/Block/Block"

function NotificationForm() {
  const navigate = useNavigate()
  const { id } = useParams()

  // Datos del formulario (en español)
  const [notificationData, setNotificationData] = useState({
    title: "",
    message: "",
    more_info_url: "",
    read: false,
    user: "",
  })

  // Lista de usuarios
  const [UserList, setUserList] = useState([])

  // Estados para feedback
  const [feedbackMessage, setFeedbackMessage] = useState(null)
  const [isError, setIsError] = useState(false)

  useEffect(() => {
    if (id) {
      fetchNotification()
    }
    fetchUser()
  }, [id])

  const fetchNotification = async () => {
    try {
      const notification = await notificationService.getNotificationDetails(id)
      setNotificationData(notification)
    } catch (error) {
      console.log("Error al obtener la notificación:", error)
    }
  }

  const handleChange = (event) => {
    setNotificationData({
      ...notificationData,
      [event.target.name]: event.target.value,
    })
  }

  const checkChange = (event) => {
    setNotificationData({
      ...notificationData,
      [event.target.name]: event.target.checked,
    })
  }

  const handleCreateOrUpdateNotification = async (event) => {
    event.preventDefault()
    setFeedbackMessage(null) // Reseteamos el mensaje

    try {
      if (id) {
        // Actualizar notificación existente
        await notificationService.updateNotification(id, notificationData)
      } else {
        if (notificationData.user === "all") {
          // Para enviar a todos los abogados, eliminamos el campo user del payload
          const payload = { ...notificationData }
          delete payload.user
          await notificationService.sendAllLawyers(payload)
        } else {
          await notificationService.createNotification(notificationData)
        }
      }
      setFeedbackMessage("Notificación enviada correctamente.")
      setIsError(false)
      // Opcional: redirigir luego de un tiempo
      // navigate("/notification")
    } catch (error) {
      console.log("Error al crear/actualizar la notificación:", error)
      setFeedbackMessage("Ha habido algún error al enviar la notificación.")
      setIsError(true)
    }
  }

  const fetchUser = async () => {
    try {
      const dataUser = await notificationService.getUserList()
      setUserList(dataUser)
    } catch (error) {
      console.error("Error al obtener la lista de usuarios:", error)
    }
  }

  const pageTitle = id ? "Editar Notificación" : "Agregar Notificación"
  const submitButtonText = id ? "Actualizar" : "Guardar"

  return (
    <Layout title={pageTitle} content="container">
      <Block>
        <Card className="card-gutter-md">
          <Card.Body>
            <div className="bio-block">
              <h4 className="bio-block-title mb-4">{pageTitle}</h4>
              <Form onSubmit={handleCreateOrUpdateNotification}>
                <Row className="g-3">
                  {/* Campo TÍTULO */}
                  <Col lg="12">
                    <Form.Group className="form-group">
                      <Form.Label htmlFor="title">Título</Form.Label>
                      <div className="form-control-wrap">
                        <Form.Control
                          type="text"
                          id="title"
                          placeholder="Ingresa el título"
                          name="title"
                          value={notificationData.title}
                          onChange={handleChange}
                        />
                      </div>
                    </Form.Group>
                  </Col>

                  {/* Campo MENSAJE */}
                  <Col lg="12">
                    <Form.Group className="form-group">
                      <Form.Label htmlFor="message">Mensaje</Form.Label>
                      <div className="form-control-wrap">
                        <Form.Control
                          type="text"
                          id="message"
                          placeholder="Ingresa el mensaje"
                          name="message"
                          value={notificationData.message}
                          onChange={handleChange}
                        />
                      </div>
                    </Form.Group>
                  </Col>

                  {/* Campo ENLACE (más_info_url) */}
                  <Col lg="12">
                    <Form.Group className="form-group">
                      <Form.Label htmlFor="more_info_url">Enlace</Form.Label>
                      <div className="form-control-wrap">
                        <Form.Control
                          type="text"
                          id="more_info_url"
                          placeholder="Ingresa la URL"
                          name="more_info_url"
                          value={notificationData.more_info_url}
                          onChange={handleChange}
                        />
                      </div>
                    </Form.Group>
                  </Col>

                  {/* Campo LEÍDO (checkbox) */}
                  <Col lg="12">
                    <Form.Group className="form-group">
                      <Form.Check
                        type="checkbox"
                        id="read"
                        name="read"
                        checked={notificationData.read}
                        onChange={checkChange}
                        label="Leído"
                      />
                    </Form.Group>
                  </Col>

                  {/* SELECT de USUARIO */}
                  <Col lg="12">
                    <Form.Group className="form-group">
                      <Form.Label htmlFor="user">Abogado</Form.Label>
                      <div className="form-control-wrap">
                        <Form.Select
                          id="user"
                          name="user"
                          value={notificationData.user}
                          onChange={handleChange}
                          className="custom-select"
                        >
                          <option value="">Selecciona un abogado</option>
                          <option value="all">Todos los abogados</option>
                          {UserList?.map((user, idx) => (
                            <option key={idx} value={user.id}>
                              {user.name}
                            </option>
                          ))}
                        </Form.Select>
                      </div>
                    </Form.Group>
                  </Col>

                  {/* BOTÓN SUBMIT */}
                  <Col lg="12">
                    <Button variant="primary" type="submit">
                      {submitButtonText}
                    </Button>
                  </Col>
                </Row>
              </Form>

              {/* Sección para mostrar feedback de éxito/error */}
              {feedbackMessage && (
                <Alert variant={isError ? "danger" : "success"} className="mt-3">
                  {feedbackMessage}
                </Alert>
              )}
            </div>
          </Card.Body>
        </Card>
      </Block>
    </Layout>
  )
}

export default NotificationForm
