import { useState, useEffect } from "react"
import { Button, Row, Col, Form, Modal } from "react-bootstrap"
import campaignService from "../../campaign/services/campaignService"
import lawyerService from "../../lawyer/services/lawyerService"
import lead_assignmentService from "../../Lead_assignment/services/Lead_assignmentService"
import { message, Spin } from "antd"
import Select from "react-select" // <-- Importamos react-select

function LeadAssignmentForm({ show, handleClose, lead, updateLead }) {
  const [loading, setLoading] = useState(false)
  const [initialLoading, setInitialLoading] = useState(false)

  const [leadData, setLeadData] = useState({
    lead: lead ? lead.id : null,
    lawyer: null,
    assignment_order: 1,
    campaign: null,
  })

  const [campaign, setCampaign] = useState([])
  const [lawyer, setLawyer] = useState([])

  // Guardaremos la "opción" (objeto) que seleccionemos en react-select
  // o null si no hay selección
  const [selectedLawyerOption, setSelectedLawyerOption] = useState(null)

  useEffect(() => {
    fetchAll()
  }, [])

  useEffect(() => {
    if (lead) {
      setLeadData((prevData) => ({ ...prevData, lead: lead.id }))
    }
  }, [lead])

  const fetchAll = async () => {
    try {
      setInitialLoading(true)
      await Promise.all([fetchCampaignList(), fetchLawyer()])
    } catch (error) {
      console.error("Error fetching data:", error)
    } finally {
      setInitialLoading(false)
    }
  }

  const fetchLawyer = async () => {
    const response = await lawyerService.getRealLawyerList()
    setLawyer(response)
  }

  const fetchCampaignList = async () => {
    const response = await campaignService.getCampaignList()
    setCampaign(response)
  }

  const handleCreateOrUpdateLead = async (event) => {
    event.preventDefault()
    try {
      setLoading(true)

      // lawyer en leadData será el .value del selectedLawyerOption
      const dataToSend = {
        ...leadData,
        lawyer: selectedLawyerOption ? selectedLawyerOption.value : null,
      }

      const data = await lead_assignmentService.createLead_assignment(dataToSend)
      updateLead(dataToSend.lead, data.lawyer)
      handleClose()
    } catch (error) {
      console.log("Error creating/updating lead:", error)
      message.error("Error asignando lead", 4)
    } finally {
      setLoading(false)
    }
  }

  // Generamos las "options" para react-select
  // value => ID del lawyer, label => texto que ve el usuario
  const lawyerOptions = lawyer.map((l) => ({
    value: l.id,
    label: `${l.id} | ${l.name} | ${l.last_name} | ${l.email}`,
  }))

  // Manejo de changes en AssignmentOrder y Campaign
  const handleChange = (event) => {
    setLeadData({ ...leadData, [event.target.name]: event.target.value })
  }

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Asignar Lead</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Spin spinning={initialLoading} size="large">
          {/* Importante: onSubmit en el Form */}
          <Form onSubmit={handleCreateOrUpdateLead}>
            <Row className="g-3">
              {/* Lead */}
              <Col lg="12">
                <Form.Group className="form-group">
                  <Form.Label htmlFor="lead">Lead</Form.Label>
                  <div className="form-control-wrap">
                    <Form.Control
                      type="text"
                      id="lead"
                      name="lead"
                      value={lead?.name || ""}
                      readOnly
                      className="custom-select"
                    />
                  </div>
                </Form.Group>
              </Col>

              {/* Lawyer => react-select */}
              <Col lg="12">
                <Form.Group className="form-group">
                  <Form.Label>Lawyer</Form.Label>
                  <div className="form-control-wrap">
                    <Select
                      options={lawyerOptions}           // lista de opciones
                      value={selectedLawyerOption}       // opción seleccionada
                      onChange={(selected) => {          // evento al cambiar
                        setSelectedLawyerOption(selected)
                      }}
                      isSearchable={true}                // permite búsqueda
                      placeholder="Selecciona un lawyer..."
                      // Estilo si deseas un ancho mayor:
                      styles={{
                        container: (base) => ({
                          ...base,
                          width: "100%",
                        }),
                      }}
                    />
                  </div>
                </Form.Group>
              </Col>

              {/* Assignment Order */}
              <Col lg="12">
                <Form.Group className="form-group">
                  <Form.Label htmlFor="assignment_order">
                    Assignment Order
                  </Form.Label>
                  <div className="form-control-wrap">
                    <Form.Control
                      type="number"
                      id="assignment_order"
                      name="assignment_order"
                      value={leadData.assignment_order}
                      onChange={handleChange}
                    />
                  </div>
                </Form.Group>
              </Col>

              {/* Campaign opcional */}
              <Col lg="12">
                <Form.Group className="form-group">
                  <Form.Label htmlFor="campaign">
                    Campaign (Opcional)
                  </Form.Label>
                  <div className="form-control-wrap">
                    <Form.Select
                      id="campaign"
                      name="campaign"
                      value={leadData.campaign}
                      onChange={handleChange}
                      className="custom-select"
                    >
                      <option value="">Select campaign</option>
                      {campaign?.map((camp, idx) => (
                        <option key={idx} value={camp.id}>
                          {camp.specialty?.child_specialty} |{" "}
                          {camp.province?.name || "Toda España"} | Abogado:{" "}
                          {camp.lawyer}
                        </option>
                      ))}
                    </Form.Select>
                  </div>
                </Form.Group>
              </Col>

              {/* Botón para asignar */}
              <Col lg="12">
                <Button variant="primary" type="submit">
                  {loading ? "loading..." : "Asignar"}
                </Button>
              </Col>
            </Row>
          </Form>
        </Spin>
      </Modal.Body>
    </Modal>
  )
}

export default LeadAssignmentForm
