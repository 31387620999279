import React, { useEffect, useState } from "react"
import DataTable from "react-data-table-component"
import exportFromJSON from "export-from-json"
import { Button } from "react-bootstrap"
import * as XLSX from "xlsx"
import DataTablePagination from "../Pagination/DataTablePagination"
import DataTableBackEndPagination from "../Pagination/DataTableBackEndPagination"

// export file component
const Export = ({ data }) => {
  const fileName = "user-data"

  const exportCSV = () => {
    const exportType = exportFromJSON.types.csv
    exportFromJSON({ data, fileName, exportType })
  }
  const exportExcelXLSX = (data, fileName) => {
    // Crear un nuevo libro de trabajo
    const workbook = XLSX.utils.book_new()

    // Convertir los datos a una hoja de trabajo
    const worksheet = XLSX.utils.json_to_sheet(data)

    // Añadir la hoja de trabajo al libro con un nombre de hoja
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1")

    // Opciones para escribir el archivo (puedes ajustar según necesidad)
    const writeOptions = {
      bookType: "xlsx", // Tipo de libro a escribir
      type: "binary", // Formato del libro (binary string)
    }

    // Escribir el libro de trabajo a un archivo .xlsx
    XLSX.writeFile(workbook, `${fileName}.xlsx`, writeOptions)
  }
  const exportExcel = () => {
    const exportType = exportFromJSON.types.xls
    // exportFromJSON({ data, fileName, exportType })
    exportExcelXLSX(data, fileName)
  }

  return (
    <div className="export-options d-flex align-items-center me-2">
      <div className="export-title small me-2">Exportar</div>
      <div className="btn-group">
        <Button variant="outline-light" onClick={() => exportCSV()}>
          CSV
        </Button>
        <Button variant="outline-light" onClick={() => exportExcel()}>
          Excel
        </Button>
      </div>
    </div>
  )
}

// expanded component in mobile view
const expandedComponent = ({ data }) => {
  return (
    <ul className="data-details p-3 gap gy-1 border-bottom small">
      <li>
        <span className="data-title text-base fw-medium me-2">Name:</span>
        <span className="data-text text-light">{data.name}</span>
      </li>
      <li>
        <span className="data-title text-base fw-medium me-2">Age:</span>
        <span className="data-text text-light">{data.age}</span>
      </li>
      <li>
        <span className="data-title text-base fw-medium me-2">Position:</span>
        <span className="data-text text-light">{data.position}</span>
      </li>
      <li>
        <span className="data-title text-base fw-medium me-2">Company:</span>
        <span className="data-text text-light">{data.company}</span>
      </li>
      <li>
        <span className="data-title text-base fw-medium me-2">Start Date:</span>
        <span className="data-text text-light">{data.startDate}</span>
      </li>
      <li>
        <span className="data-title text-base fw-medium me-2">Salary:</span>
        <span className="data-text text-light">{data.salary}</span>
      </li>
    </ul>
  )
}

// custom checkbox
const customCheckbox = React.forwardRef(({ onClick, ...rest }, ref) => (
  <div className="form-check" id={rest.name}>
    <input
      type="checkbox"
      className="form-check-input"
      ref={ref}
      onClick={onClick}
      {...rest}
    />
  </div>
))

function DataTableComponent({
  data,
  columns,
  className,
  expandableRows,
  actions,
  tableClassName,
  selectableRows,
  pageCount,
  searchByList = ["name"],
  backEndPagination = false,
  fetchPaginatedData = false,
  currentPaginationPage = false,
  backendFilter = false,
  filterFunc = false,
  isLeadForm = false,
  customExpandedComponent = false,
  ...props
}) {
  const [tableData, setTableData] = useState(data)
  const [showItemPerPage, setShowItemPerPage] = useState(10)
  const [mobileView, setMobileView] = useState(false)
  const [searchBy, setSearchBy] = useState({})
  const [filterBy, setFilterBy] = useState({})
  const [loading, setLoading] = useState(false)
  const [showItemPerPageBackend, setShowItemPerPageBackend] = useState(10)
  const [itemPerPageBackend, setItemPerPageBackend] = useState(10)

  /**
   * Filters the `data` array based on the criteria specified in `searchBy`.
   * Sets the loading state before starting the filter operation and clears it after the operation is complete.
   */
  const handleFilter = async () => {
    setLoading(true) // Activa el estado de carga

    let filteredData = data
    if (backendFilter) {
      Object.keys(searchBy).forEach((key) => {
        if (searchBy[key].text) {
          searchBy[key] = searchBy[key].text
        }
      })
      await filterFunc(1, searchBy)
      setFilterBy(searchBy)
    } else {
      Object.keys(searchBy).forEach((key) => {
        const searchKey = searchBy[key]?.key

        if (searchBy[key]?.list?.length > 0) {
          const filteredList = searchBy[key].list.filter((listItem) => {
            const searchKey = searchBy[key].key
            return listItem[searchKey]
              ? listItem[searchKey]
                  .toLowerCase()
                  .includes(searchBy[key]?.text.toLowerCase())
              : false
          })

          filteredData = filteredData.filter((item) =>
            filteredList.some((listItem) => listItem.id === item[key])
          )
        } else if (searchKey) {
          filteredData = filteredData.filter(
            (item) =>
              item[key] &&
              item[key][searchKey] &&
              item[key][searchKey]
                .toLowerCase()
                .includes(searchBy[key]?.text.toLowerCase())
          )
        } else if (searchBy[key]?.text) {
          filteredData = filteredData.filter((item) => {
            return (
              item[key] &&
              String(item[key])
                .toLowerCase()
                .includes(searchBy[key]?.text.toLowerCase())
            )
          })
        }
      })
      setTableData(filteredData)
    }

    setLoading(false)
  }

  // function to change the table design view to expandable under 1200 px
  const viewChange = () => {
    if (window.innerWidth < 960 && expandableRows) {
      setMobileView(true)
    } else {
      setMobileView(false)
    }
  }

  useEffect(() => {
    window.addEventListener("load", viewChange)
    window.addEventListener("resize", viewChange)
    return () => {
      window.removeEventListener("resize", viewChange)
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps
  useEffect(() => {
    setTableData(data)
  }, [data])

  return (
    <div className="data-table-wrapper">
      <div className="data-table-top" style={{ flexWrap: "wrap" }}>
        {searchByList.map((term) => {
          if (Array.isArray(term)) {
            return (
              <div className="data-table-search">
                <input
                  className="form-control"
                  placeholder={"Buscar por " + term[0]}
                  type="text"
                  value={searchBy[term[0]]?.text ? searchBy[term[0]]?.text : ""}
                  onChange={(e) => {
                    setSearchBy({
                      ...searchBy,
                      [term[0]]: {
                        text: e.target.value,
                        key: term[1],
                        list: term[2],
                      },
                    })
                  }}
                />
              </div>
            )
          } else {
            return (
              <div className="data-table-search">
                <input
                  className="form-control"
                  placeholder={"Buscar por " + term}
                  type="text"
                  value={searchBy[term]?.text ? searchBy[term]?.text : ""}
                  onChange={(e) => {
                    setSearchBy({
                      ...searchBy,
                      [term]: {
                        text: e.target.value,
                      },
                    })
                  }}
                />
              </div>
            )
          }
        })}
        <Button
          className="d-none d-md-inline-flex"
          variant="primary"
          onClick={() => {
            handleFilter()
          }}
        >
          {loading ? <span>Filtrando...</span> : <span>Filtrar</span>}
        </Button>
        <Button
          className="d-none d-md-inline-flex"
          variant="primary"
          onClick={() => {
            setSearchBy({})
            setTableData(data)
            if (backendFilter) {
              filterFunc(1)
              setFilterBy({})
            }
          }}
        >
          <span>Limpiar filtro</span>
        </Button>
        <br></br>
        <div className="data-table-action-wrap" style={{ paddingTop: "1%" }}>
          {actions && <Export data={data} />}
          {!backEndPagination && (
            <div className="data-table-select">
              <select
                className="form-select"
                onChange={(e) => setShowItemPerPage(e.target.value)}
                value={showItemPerPage}
              >
                <option value="5">5</option>
                <option value="10">10</option>
                <option value="15">15</option>
                <option value="20">20</option>
                <option value="25">25</option>
                <option value="50">50</option>
                <option value="100">100</option>
              </select>
              <span className="text">Registros por página</span>
            </div>
          )}
          {backEndPagination && isLeadForm && (
            <div className="data-table-select">
              <select
                className="form-select"
                onChange={async (e) => {
                  setLoading(true)
                  setShowItemPerPageBackend(e.target.value)
                  setItemPerPageBackend(e.target.value)
                  await filterFunc(1, filterBy, e.target.value)
                  setLoading(false)
                }}
                value={showItemPerPageBackend}
              >
                <option value="5">5</option>
                <option value="10">10</option>
                <option value="15">15</option>
                <option value="20">20</option>
                <option value="25">25</option>
                <option value="50">50</option>
                <option value="100">100</option>
              </select>
              <span className="text">Leads por página</span>
            </div>
          )}
        </div>
      </div>
      <DataTable
        // columns={columns}
        data={tableData}
        className={tableClassName}
        noDataComponent={<div className="p-2">There are no records found.</div>}
        sortIcon={<div className="data-table-sorter"></div>}
        pagination
        expandableRows={customExpandedComponent ? true : mobileView}
        expandableRowsComponent={customExpandedComponent || expandedComponent}
        selectableRows={selectableRows}
        selectableRowsComponent={customCheckbox}
        columns={columns.map((column) => ({
          ...column,
          style: {
            ...column.style,
            minWidth: column.minWidth || "250px", // You can set a default width if needed
          },
        }))}
        paginationComponent={({
          rowsPerPage,
          rowCount,
          onChangePage,
          onChangeRowsPerPage,
          currentPage,
        }) => (
          <div className="data-table-bottom">
            {backEndPagination ? (
              <DataTableBackEndPagination
                showItemPerPage={showItemPerPageBackend}
                itemPerPage={itemPerPageBackend}
                totalItems={pageCount}
                paginate={backendFilter ? filterFunc : fetchPaginatedData}
                currentPage={currentPaginationPage}
                onChangeRowsPerPage={onChangeRowsPerPage}
                setShowItemPerPage={setShowItemPerPage}
                backendFilter={backendFilter}
                filterBy={filterBy}
              />
            ) : (
              <DataTablePagination
                showItemPerPage={showItemPerPage}
                itemPerPage={rowsPerPage}
                totalItems={rowCount}
                paginate={onChangePage}
                currentPage={currentPage}
                onChangeRowsPerPage={onChangeRowsPerPage}
                setShowItemPerPage={setShowItemPerPage}
              />
            )}
          </div>
        )}
      />
    </div>
  )
}

export default DataTableComponent
