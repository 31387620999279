import { useState, useEffect } from "react"
import { Card, Button, Row, Col, Form } from "react-bootstrap"
import { useNavigate, useParams } from "react-router-dom"
import notificationSettingsService from "../services/notification_settingsService"
import Layout from "../../../layout/default"
import Block from "../../../components/Block/Block"

function Notification_settingsForm() {
  const navigate = useNavigate()
  const { id } = useParams()

  const [notification_settingsData, setNotification_settingsData] = useState({
    potencial_lead: true,
    monthly_resume: true,
    new_bill: true,
    send_email: true,
    send_sms: true,
  })

  useEffect(() => {
    if (id) {
      fetchNotification_settings()
    }
  }, [id])

  const fetchNotification_settings = async () => {
    try {
      const notification_settings =
        await notificationSettingsService.getNotification_settingsDetails(id)
      setNotification_settingsData(notification_settings)
    } catch (error) {
      console.log("Error fetching notification_settings:", error)
    }
  }

  const handleChange = (event) => {
    setNotification_settingsData({
      ...notification_settingsData,
      [event.target.name]: event.target.value,
    })
  }
  const checkChange = (event) => {
    setNotification_settingsData({
      ...notification_settingsData,
      [event.target.name]: event.target.checked,
    })
  }

  const handleCreateOrUpdateNotification_settings = async (event) => {
    event.preventDefault()

    try {
      if (id) {
        await notificationSettingsService.updateNotification_settings(
          id,
          notification_settingsData
        )
      } else {
        await notificationSettingsService.createNotification_settings(
          notification_settingsData
        )
      }
      navigate("/notification_settings")
    } catch (error) {
      console.log("Error creating/updating notification_settings:", error)
    }
  }

  const pageTitle = id
    ? "Editar ajuste de las notificaciones"
    : "Añadir ajuste para las notificaciones"
  const submitButtonText = id ? "Update" : "Save"

  return (
    <Layout title={pageTitle} content="container">
      <Block>
        <Card className="card-gutter-md">
          <Card.Body>
            <div className="bio-block">
              <h4 className="bio-block-title mb-4">{pageTitle}</h4>
              <Form onSubmit={handleCreateOrUpdateNotification_settings}>
                <Row className="g-3">
                  <Col lg="12">
                    <Form.Group className="form-group">
                      <Form.Label htmlFor="email">email</Form.Label>
                      <div className="form-control-wrap">
                        <Form.Control
                          type="text"
                          id="email"
                          placeholder="email"
                          name="Email"
                          value={notification_settingsData.email}
                          onChange={handleChange}
                        />
                      </div>
                    </Form.Group>
                  </Col>
                  <Col lg="12">
                    <Form.Group className="form-group">
                      <Form.Label htmlFor="phone_number">
                        Numero de teléfono
                      </Form.Label>
                      <div className="form-control-wrap">
                        <Form.Control
                          type="text"
                          id="phone_number"
                          placeholder="phone_number"
                          name="Número de teléfono"
                          value={notification_settingsData.phone_number}
                          onChange={handleChange}
                        />
                      </div>
                    </Form.Group>
                  </Col>
                  <Col lg="12">
                    <Form.Group className="form-group">
                      <Form.Check
                        type="checkbox"
                        id="potencial_lead"
                        name="potencial_lead"
                        value={notification_settingsData.potencial_lead}
                        checked={notification_settingsData.potencial_lead}
                        label="Lead Potencial"
                        onChange={checkChange}
                      />
                    </Form.Group>
                  </Col>
                  <Col lg="12">
                    <Form.Group className="form-group">
                      <Form.Check
                        type="checkbox"
                        id="monthly_resume"
                        name="monthly_resume"
                        value={notification_settingsData.monthly_resume}
                        checked={notification_settingsData.monthly_resume}
                        label="Resumen Mensual"
                        onChange={checkChange}
                      />
                    </Form.Group>
                  </Col>
                  <Col lg="12">
                    <Form.Group className="form-group">
                      <Form.Check
                        type="checkbox"
                        id="new_bill"
                        name="new_bill"
                        value={notification_settingsData.new_bill}
                        checked={notification_settingsData.new_bill}
                        label="Nueva Factura"
                        onChange={checkChange}
                      />
                    </Form.Group>
                  </Col>

                  <Col lg="12">
                    <Form.Group className="form-group">
                      <Form.Check
                        type="checkbox"
                        id="send_email"
                        name="send_email"
                        value={notification_settingsData.send_email}
                        checked={notification_settingsData.send_email}
                        label="Enviar Email"
                        onChange={checkChange}
                      />
                    </Form.Group>
                  </Col>
                  <Col lg="12">
                    <Form.Group className="form-group">
                      <Form.Check
                        type="checkbox"
                        id="send_sms"
                        name="send_sms"
                        value={notification_settingsData.send_sms}
                        checked={notification_settingsData.send_sms}
                        label="Enviar SMS"
                        onChange={checkChange}
                      />
                    </Form.Group>
                  </Col>
                  <Col lg="12">
                    <Button variant="primary" type="submit">
                      {submitButtonText}
                    </Button>
                  </Col>
                </Row>
              </Form>
            </div>
          </Card.Body>
        </Card>
      </Block>
    </Layout>
  )
}

export default Notification_settingsForm
