export const COLOR_STATE_MAP = {
    "No contactado": "#478FFC",
    "1er intento": "#478FFC",
    "Contactado": "#F2BC16",
    "Presupuestado": "#F2BC16",
    "Aceptado": "#2DC58C",
    "Perdido": "#DF3C4E",
    "Asesoría": "#787C9E",
    "Consulta": "#787C9E",
    "Abonado": "#2DC58C",
    "Caso cerrado": "#787C9E",
    "No contactable": "#DF3C4E",
    "Caso no viable": "#DF3C4E",
  }